<template>
  <div>
    <div
      class="rounded align-center p-3 flex justify-between"
      style="background-color: #dbe2f5"
    >
      <h2 class="text-lg font-bold text-primary text-uppercase">
        Practice Test <small class="text-gray-500">( Total 35 Practice )</small>
      </h2>
      <div class="flex align-center">
        <div class="bg-periwinkle-gray rounded p-2 ml-2">
          <md-icon class="m-0">filter_alt</md-icon>
        </div>
      </div>
    </div>
    <Table :items="questions" @onSelect="onSelect">
      <template
        slot="row"
        slot-scope="{ item }"
        md-selectable="single"
      >
        <md-table-cell md-numeric>{{ item.id }}</md-table-cell>
        <md-table-cell>{{ item.code }}</md-table-cell>
        <md-table-cell>{{ item.title }}</md-table-cell>
        <md-table-cell>
          <span class="p-1 rounded text-base bg-victoria text-white">RO-5</span>
          <span class="p-1 rounded text-base ml-1 bg-victoria text-white"
            >S-7</span
          >
          <span class="p-1 rounded text-base ml-1 bg-victoria text-white"
            >RS-3</span
          >
          <span class="p-1 rounded text-base ml-1 bg-victoria text-white"
            >MCQSA-12</span
          >
        </md-table-cell>
        <md-table-cell>
          <md-icon class="p-4 text-3xl outline-gray-400 rounded"
            >turned_in</md-icon
          >
        </md-table-cell>
      </template>
    </Table>
    <div class="flex justify-between align-center my-6">
        <md-button class="bg-victoria text-white text-italic rounded ml-0">Back</md-button>
        <Paginate
        :start="1"
        :end="20"
        :total="100"
        :limit="20"
        />
      </div>
  </div>
</template>

<script>
import { Table, Paginate } from "@/components";
import data from "@/data/practice-test/data";
export default {
  data() {
    return {
      questions: data.questions,
    };
  },
  components: {
    Paginate,
    Table
  },
  methods: {
    onSelect(item) {
      this.$router.push({
        name: "student.practiceTestQuestionParticipation",
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style></style>
