<template>
  <div class="p-2 bg-athens-gray rounded mb-6">
      <h2 class="mt-2 mb-3 text-uppercase">{{ label }}</h2>

      <ul>
          <li v-for="(item, index) in items" :key="index" class="flex justify-between text-base font-bold">
             <p class="my-2"> {{ item.name }}</p>
             <span class="text-victoria">{{ item.score }}</span>
          </li>
      </ul>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    }
}
</script>

<style>

</style>