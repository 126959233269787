<template>
  <div class="p-6">
    <div class="inline-flex align-center">
      <img  src="/images/logo.png" alt="" />
      <h2 class="border-l border-solid border-gray-500 ml-2 pl-2">
        PTE ACADEMIC
      </h2>
    </div>
    <h2 class="text-victoria text-2xl mb-2">Test Taker Score Report</h2>
    <div class="flex justify-between">
      <div>
        <h2 class="text-2xl my-3">
          Mr. John Wick
          <small class="px-1 bg-victoria text-white rounded"
            >Score report code: 016487ABS124FU</small
          >
        </h2>
        <md-table class="student-result-table">
          <md-table-row>
            <md-table-cell>
              <p class="text-base font-semibold">Test Taker ID</p>
            </md-table-cell>
            <md-table-cell>
              <p class="text-base font-bold text-victoria">PTE1016481735AFUG</p>
            </md-table-cell>

            <md-table-cell>
              <p class="text-base font-semibold">Branch</p>
            </md-table-cell>
            <md-table-cell>
              <p class="text-base font-bold text-victoria">Dhanmondi</p>
            </md-table-cell>
          </md-table-row>

          <md-table-row>
            <md-table-cell>
              <p class="text-base font-semibold">Date of birht</p>
            </md-table-cell>
            <md-table-cell>
              <p class="text-base font-bold text-victoria">29 Oct, 1990</p>
            </md-table-cell>

            <md-table-cell>
              <p class="text-base font-semibold">Course</p>
            </md-table-cell>
            <md-table-cell style="width: 250px">
              <p class="text-base font-bold text-victoria">Exp. Listening</p>
            </md-table-cell>
          </md-table-row>
          <md-table-row v-for="(item, index) in resultsInfo" :key="index">
            <md-table-cell style="width: 250px">
              <p class="text-base font-semibold">{{ item.key }}</p>
            </md-table-cell>
            <md-table-cell>
              <p class="text-base font-bold text-victoria">{{ item.value }}</p>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <h2 class="text-2xl my-3">
          Feedback
          <small class="px-1 bg-victoria text-base text-white rounded"
            >Total Feedback (15)
          </small>
        </h2>
        <p class="text-base line-height-27 font-semibold">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste ex
          praesentium nesciunt enim dolorem nostrum, suscipit deserunt atque
          asperiores natus vel minima, culpa unde, blanditiis dolor consequatur
          delectus magni non nemo placeat.
        </p>
      </div>
      <div>
        <img src="/images/result-sheet.png" alt="" />
      </div>
    </div>
    <h2 class="text-2xl my-3">
      Skill Profile
    </h2>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-size-25">
        <SkillSummery label="Communication Skill" :items="items1" />
        <SkillSummery label="Enablling Skill" :items="items2" />
      </div>
      <div class="md-layout-item">
        <SkillGraph />
      </div>
    </div>
    <h2 class="text-2xl my-3">
          Note of instruction
        </h2>
        <p class="text-base line-height-27 font-semibold">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste ex
          praesentium nesciunt enim dolorem nostrum, suscipit deserunt atque
          asperiores natus vel minima, culpa unde, blanditiis dolor consequatur
          delectus magni non nemo placeat.
        </p>
  </div>
</template>

<script>
import SkillSummery from "@/components/molecule/result/SkillSummery";
import SkillGraph from "@/components/molecule/result/SkillGraph";
export default {
  components: {
    SkillSummery,
    SkillGraph
  },
  data() {
    return {
      resultsInfo: [
        { key: "Country of ciitzenship", value: "India" },
        { key: "Country of residence", value: "Australia" },
        { key: "gender", value: "Male" },
        { key: "Email Address", value: "johnwick@company.com" },
        { key: "Registration ID", value: "3592146512" },
        { key: "Test Date", value: "7 May, 2019" },
        { key: "Test Center Country", value: "Australia" },
        { key: "Test Center ID", value: "67721" },
        { key: "First Time test taker", value: "No" },
        { key: "Report issues date", value: "7 May, 2019" },
        { key: "Score valid until", value: "7 May, 2021" },
      ],
      items1: [
        { name: "Reading", score: 78 },
        { name: "Writing", score: 75 },
        { name: "Lestening", score: 65 },
        { name: "Speaking", score: 85 },
      ],
      items2: [
        { name: "Grammar", score: 78 },
        { name: "Oral Fluency", score: 75 },
        { name: "Pronounciation", score: 65 },
        { name: "Spelling", score: 55 },
        { name: "Vocabulary", score: 55 },
        { name: "Written Discourse", score: 55 },
      ],
    };
  },
};
</script>

<style>
.student-result-table .md-table-cell {
  border: none;
}
</style>
