<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  mounted() {
    this.renderChart(
      {
        labels: [
          "Reading",
          "Writing",
          "Speaking",
          "Listening",
          "Grammar",
          "Oral Fluency",
          "Pronounciation",
          "Spelling",
          "Vocabulary",
          "Written Discourse"
        ],
        datasets: [
          {
            label: "Communicative Skill",
            backgroundColor: "#514b99",
            data: [60, 40, 72, 39, 65, 50, 39, 80, 40, 96]
          }
        ]
      },
      { responsive: true, maintainAspectRatio: false }
    );
  }
};
</script>
