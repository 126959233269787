const questions = [];

for(let i = 1; i <= 15; i++) {
    questions.push({
        id: i, code: 'PTT2001', title: 'Football Bangladesh'
    });
}

export default {
    questions
}