<template>
  <div class="p-6">
    <div>
      <h3 class="text-uppercase text-lg">Your score card</h3>
      <p class="text-uppercase  text-lg mt-3">
        Score Report Code:
        <span class="text-victoria font-bold">6541556FGB45</span>
      </p>
    </div>
    <div class="md-layout md-gutter mt-10">
      <div class="md-layout-item flex align-center md-size-75 md-small-size-60 md-xsmall-size-100">
        <div>
          <img src="/images/avatar.png" alt="" />
        </div>
        <div class="ml-6 inline-block">
          <h2 class="text-uppercase text-xl">{{ result.student_name }}</h2>
          <p class="text-uppercase text-base mt-2">{{ result.student_email }}</p>
          <p class="text-uppercase  text-lg mt-2">
            Test Tracker ID:
            <span class="text-victoria font-bold">6541556FGB45</span>
          </p>
          <p class="text-uppercase  text-lg mt-2">
            REG. ID:
            <span class="text-victoria font-bold">6541556FGB45</span>
          </p>
        </div>
      </div>
      <div class="md-layout-item md-size-25 md-small-size-40 md-xsmall-size-100">
        <div class="border-l border-solid border-gray-500 text-right m-2">
          <div
              class="bg-victoria rounded-tr rounded-tl pointer w-50p text-center h-40 inline-block p-1 text-white font-semibold text-uppercase rounded-b-full text-lg"
              @click="dialog(true)"
          >
            <p class="mt-3 mb-10">Overall Score</p>
            <span class="text-5xl font-bold">{{ result.overall_score }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-10">
      <div class="outline-gray-400 p-1 rounded center">
        <md-icon
            class="bg-victoria p-3 text-white m-0 rounded text-2xl pointer"
            @click.native="switchQuestion('prev')"
        >keyboard_arrow_left</md-icon>
        <span class="mx-6 text-lg font-semibold text-uppercase">TEST NAME: {{ testObject.test }}</span>
        <md-icon
            class="bg-victoria p-3 text-white m-0 rounded text-2xl pointer"
            @click.native="switchQuestion('next')"
        >keyboard_arrow_right</md-icon>
      </div>
    </div>
    <h2 class="mt-10 mb-6 text-uppercase font-semibold">
      Communicative Skills
    </h2>
    <div class="w-50p w-xs-full w-sm-full flex justify-between">
      <Badge class="m-1" color="#1f3799" label="Reading" :value="result.get_reading_total_marks" />
      <Badge class="m-1" color="#089992" label="Writing" :value="result.get_writing_total_marks" />
      <Badge class="m-1" color="#e55038" label="Speaking" :value="result.get_speaking_total_marks" />
      <Badge class="m-1" color="#e58e25" label="Listening" :value="result.get_listening_total_marks" />
    </div>

    <h2 class="mt-10 mb-6 text-uppercase font-semibold">Skills Breakdown</h2>
    <div>
      <SkillProgress label="Reading" :value="result.reading_percentage" color="#1f3799" />
      <SkillProgress label="Writing" :value="result.writing_percentage" color="#089992" />
      <SkillProgress label="Speaking" :value="result.speaking_percentage" color="#e55038" />
      <SkillProgress label="Listening" :value="result.listening_percentage" color="#e58e25" />

      <div class="mt-10"></div>
      <SkillProgress label="Grammer" :value="result.grammar_percentage" color="#999" />
      <SkillProgress label="Oral Fluency" :value="result.fluency_percentage" color="#999" />
      <SkillProgress label="Pronunciation" :value="result.pronunciation_percentage" color="#999" />
      <SkillProgress label="Spelling" :value="result.spelling_percentage" color="#999" />
      <SkillProgress label="Discourse" :value="result.discourse_percentage" color="#999" />
      <SkillProgress label="Vucabulary" :value="result.vocabulary_percentage" color="#999" />
    </div>

    <h2 class="mt-10 mb-6 text-uppercase font-semibold">Test Centre Information</h2>
    <div>
      <p class="text-uppercase  text-lg mt-2">
        Test Date:
        <span class="font-bold">{{ result.test_date }}</span>
      </p>

      <p class="text-uppercase  text-lg mt-2">
        Valid Untile:
        <span class="font-bold">{{ result.valid_until }}</span>
      </p>

      <!-- <p class="text-uppercase  text-lg mt-2">
         Test Centre Country:
          <span class="font-bold">UK</span>
      </p>

      <p class="text-uppercase  text-lg mt-2">
         Test Centre ID:
          <span class="font-bold">2022156GH</span>
      </p>
      <p class="text-uppercase  text-lg mt-2">
         Test Centre:
          <span class="font-bold">Holborn</span>
      </p> -->

    </div>
    <Dialog>
      <OverallStudentResult />
    </Dialog>
  </div>
</template>

<script>
import { Badge, SkillProgress, Dialog } from "@/components";
import OverallStudentResult from "@/components/molecule/result/OverallStudentResult"
import { mapActions, mapMutations } from 'vuex'
export default {
  components: {
    Badge,
    Dialog,
    SkillProgress,
    OverallStudentResult
  },
  data() {
    return {
      studentEnrolledTests: [],
      currentIndex: 0,
      result: {},
    }
  },
  computed: {
    userId() {
      return localStorage.getItem('user_id');
    },
    testObject() {
      return this.studentEnrolledTests[this.currentIndex] ? this.studentEnrolledTests[this.currentIndex] : '';
    }
  },
  methods: {
    ...mapMutations({
      dialog: 'setShowDialog',
      setShowSnackbar: "setShowSnackbar",
    }),
    ...mapActions({
      actStudentExamEnrolledList: "examination/actStudentExamEnrolledList",
      actStudentExamEnrolledResult: "examination/actStudentExamEnrolledResult"
    }),
    switchQuestion(value){

      if(value == 'next') {
        if(this.currentIndex < this.studentEnrolledTests.length - 1) {
          this.currentIndex = this.currentIndex + 1;
        }else {
          this.setShowSnackbar('No more test');
          return;
        }
      }
      if(value == 'prev') {
        if(this.currentIndex > 0) {
          this.currentIndex = this.currentIndex - 1;
        }else {
          this.setShowSnackbar('No more test');
          return;
        }
      }
      this.actStudentExamEnrolledResult(this.testObject.id).then(({ items }) => {
        this.result = items;
      })
    }
  },
  created() {
    this.actStudentExamEnrolledList(`?user_id=${this.userId}`)
        .then(({ items }) => {
          this.studentEnrolledTests = items;
          this.actStudentExamEnrolledResult(this.studentEnrolledTests[0].id)
              .then(({ items }) => {
                this.result = items;
              })
        });
  }
};
</script>